/* ==========================================================================
Header
========================================================================== */
.header {
  //margin-bottom: 20px;
}

.nav-top {
  background-color: $color-style;
  border-bottom: 1px solid #e83f33;
  @media (max-width: $screen-xs-max) {
    display: none;
  }
  .nav-top-ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    float: right;
    > li {
      float: left;
      line-height: 30px;
      margin-left: 15px;
      > a, > .btn {
        display: block;
        font-size: 12px;
        margin: 0;
        padding: 0;
        line-height: inherit;
        color: #ffffff;
        border-width: 0 !important;
        background-color: transparent !important;
        &:hover {
          color: #f8fa48;
          text-decoration: none;
        }
      }
      &.open {
        > .btn-default.dropdown-toggle {
          color: #f8fa48;
        }
      }
    }
  }
}

/* ==========================================================================
Navigation Menu & Logo
========================================================================== */
.header {
  .navbar {
    //background-image: url(../images/bg-header-pt.png);
    //background-color: #fafbb7;
    background-color: $bg-color;
    margin-bottom: 0;

    -moz-box-shadow: 0px 2px 5px rgba(#000000, 0.4);
    -webkit-box-shadow: 0px 2px 5px rgba(#000000, 0.4);
    box-shadow: 0px 2px 5px rgba(#000000, 0.4);

    position: relative;
    z-index: 10;

    @media (min-width: $screen-sm-min) {
      .container .navbar-brand {
        margin-left: 0;
      }
    }
    @media (max-width: $screen-xs-max) {
      background-color: $color-style-dark;
    }
    .navbar-nav {
      a {
        text-transform: uppercase;
        &.btn-tvplay {
          background-color: $color-style-invert;
          color: #ffffff;
          @include border-radius(5px);
          padding-top: 5px;
          padding-bottom: 5px;
          margin-top: 5px;
          text-align: center;
          &:hover,
          &:focus,
          &.focus {
            background-color: $color-style-invert-dark;
            color: #ffffff;
          }

          &:active,
          &.active {
            background-color: $color-style-invert-dark;
            color: #ffffff;
          }
          @media (max-width: $screen-xs-max) {
            margin-left: floor(($grid-gutter-width / 2));
            margin-right: ceil(($grid-gutter-width / 2));
          }
        }
      }
      .dropdown {
        &:hover {
          .dropdown-menu {
            display: block;
          }
        }
        .dropdown-menu {
          background-color: rgba($bg-color, 0.9);
          > li > a {
            color: #ffffff;
            &:hover {
              color: $color-style-light;
              background-color: transparent;
            }
          }
        }
      }
      .open {
        > a {
          color: $color-style-light;
          background-color: transparent;
        }
      }
    }
    .navbar-search {
      margin-left: 10px;
      .form-control, .btn {
        padding-top: 9px;
        padding-bottom: 9px;
        height: 40px;
        border-color: rgba($color-style-light, 0.5);
        background-color: transparent;
        @extend .btn-no-shadow;
        color: #ffffff;
      }
      .btn {
        position: absolute;
        top: 0;
        right: 0;
        border-color: transparent;
        color: $color-style-light;
      }
    }
    .navbar-right {
      > li {
        &:last-child {
          a {
            color: $color-style;
          }
        }
      }
      @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        float: none !important;
        margin: 0;
        text-align: justify;
        display: table;
        width: 100%;
        clear: both;
        li {
          display: table-cell;
          float: none;
          text-align: center;
          a {
            font-size: 9px !important;
            padding: 0;
          }
        }
      }
    }
  }
  .nav-news {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7d7e7d+0,0e0e0e+100;Black+3D */
    background: rgb(125, 126, 125); /* Old browsers */
    //background: -moz-linear-gradient(top, rgba(125, 126, 125, 1) 0%, rgba(14, 14, 14, 1) 100%); /* FF3.6-15 */
    //background: -webkit-linear-gradient(top, rgba(125, 126, 125, 1) 0%, rgba(14, 14, 14, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(to bottom, rgba(125, 126, 125, 1) 0%, rgba(14, 14, 14, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7d7e7d', endColorstr='#0e0e0e', GradientType=0); /* IE6-9 */
    @media (max-width: $screen-xs-max) {
      .container {
        padding: 0;
      }
    }
    .nav-news-ul {
      overflow: hidden;
      font-size: 12px;
      line-height: 42px;
      @media (max-width: $screen-xs-max) {
        display: none;
      }
      .nav-news-sp {
        background-color: #000;
        float: left;
        text-align: center;
        background-position: right center;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: bold;
        border-left: 2px solid #000;
        padding: 0 10px;
        position: relative;
        margin-right: -30px;
        &:after {
          position: absolute;
          right: -25px;
          top: 0;
          content: "";
          width: 0;
          height: 0;
          border-top: 21px solid transparent;
          border-left: 25px solid #000;
          border-bottom: 21px solid transparent;
        }

        @media (max-width: $screen-xs-max) {
          width: 105px;
          padding-right: 15px;
          font-size: 11px;
        }
      }
      .nav-news-wrap {
        overflow: hidden;
        white-space: nowrap;
        a {
          color: #ffffff;
          margin-right: 20px;
          &:before {
            color: $color-style-invert;
            margin-right: 3px;
            @extend .zmdi;
            @extend .zmdi-caret-right;
          }
          &:hover {
            color: $color-style-invert;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .navbar-brand {
    padding: 0;
    margin-right: 10px;
    > img {
      height: 40px;
      width: auto;
    }
  }
  .header {
    .navbar {
      min-height: inherit;
      padding-top: 10px;
      @include border-radius(0);
      border-width: 0;
      .nav {
        > li {
          > a {
            font-weight: bold;
            color: #FFF;
            font-size: 13px;
            padding: 10px 10px;
            &:hover, &.active {
              color: $color-style-light;
            }
          }
          &.active a {
            color: $color-style-light;
            background-color: transparent;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .navbar-default {
    .navbar-nav > li > a {
      color: #ffffff;
    }
    .navbar-toggle .icon-bar {
      background-color: #ffffff;
    }
    /*.navbar-toggle:hover, .navbar-toggle:focus {
      background-color: $color-style-invert;
    }*/
  }
  .navbar-toggle-icolink {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 32px;
    font-size: 20px;
    color: #ffffff;
  }
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 8px;
    height: 34px;
    > img {
      height: 34px;
    }
  }
}

.nav-action-ul {
  float: left;
  width: 360px;
  padding: 5px 0;

  @media (min-width: $screen-sm-min) {
    a {
      @include border-radius(5px);
      /*background-color: #970002;
      -webkit-box-shadow: 0 1px #000, inset 0 0 2px rgba(0, 0, 0, .3);
      -moz-box-shadow: 0 1px #000, inset 0 0 2px rgba(0, 0, 0, .3);
      box-shadow: 0 1px #000, inset 0 0 2px rgba(0, 0, 0, .3);
      background-image: -webkit-linear-gradient(top, #3d3d3d, #2b2b2b);
      background-image: -moz-linear-gradient(top, #3d3d3d, #2b2b2b);
      background-image: -o-linear-gradient(top, #3d3d3d, #2b2b2b);
      background-image: -ms-linear-gradient(top, #3d3d3d, #2b2b2b);
      background-image: linear-gradient(to bottom, #3d3d3d, #2b2b2b);*/

      background-color: #000;
      border: solid 1px #464646;

      float: left;
      padding: 5px 10px;
      color: #ffffff;
      margin-right: 5px;
      &:hover, &.active {
        color: $color-style;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    padding: 0;
    a {
      float: left;
      background-color: $color-style-dark;
      padding: 10px 15px;
      color: #ffffff;
      border-right: 1px solid #FFFFFF;
      &:last-child {
        border-right-width: 0;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    float: none;
    display: table;
    width: 100%;
    border: 1px solid $navbar-default-border;
    border-top-width: 0;
    a {
      float: none;
      display: table-cell;
      font-size: 12px;
      text-align: center;
      padding: 10px 5px;
    }
  }
}
