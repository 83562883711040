.article-content {
  text-align: justify;
  img {
    display: block;
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
  table {
    max-width: 100% !important;
    @media (max-width: $screen-md-max) {
      width: 100% !important;
    }
  }
}