.container-featured {
  margin: 20px 0;
}

.featured-left {
  float: left;
  width: 820px;
  @media (max-width: $screen-md-max) {
    width: 620px;
  }
  @media (max-width: $screen-sm-max) {
    width: 400px;
  }
  @media (max-width: $screen-xs-max) {
    float: none;
    width: auto;
  }
  .featured-slide {
    float: left;
    width: 540px;
    @media (max-width: $screen-md-max) {
      width: 440px;
    }
    @media (max-width: $screen-sm-max) {
      width: auto;
      float: none;
      .cover {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .featured-large {
      .title_news {
        .article-title {
          height: auto;
          margin-top: 0;
          margin-bottom: 5px;
          font-size: 24px;
          @media (min-width: $screen-sm-min) {
            max-height: 68px;
            overflow: hidden;
          }
        }
        .summary {
          @media (min-width: $screen-sm-min) {
            max-height: 40px;
            overflow: hidden;
          }
        }
      }
    }
  }
  .featured-trending {
    float: right;
    width: 260px;
    background-color: #fff;
    height: 503px;
    @media (max-width: $screen-md-max) {
      padding-top: 2px;
      width: 160px;
      height: 434px;
    }
    @media (max-width: $screen-sm-max) {
      display: none;
    }
    .trending-list {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        border-bottom: 1px solid #cdcdcd;
        padding-bottom: 7px;
        margin-bottom: 7px;
        &:last-child {
          border-bottom-width: 0;
          margin-bottom: 0;
        }
      }
      a {
        display: block;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.4;
        figcaption {
          display: block;
          margin-top: 5px;
        }
      }
    }
  }
  .featured-related {
    clear: both;
    @media (max-width: $screen-sm-max) {
      .row {
        .col-xs-6:last-child {
          display: none;
        }
      }
    }
  }
}

.featured-right {
  float: right;
  width: 300px;
  .featured-box {
    margin-bottom: 21px;
  }
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}


