/*!--------------------------------------------------------------------------------

    Theme Name: WebApp
    Version:    1.0.0
    Author:     trungnghia112 <trungnghia112@gmail.com>

-----------------------------------------------------------------------------------*/
//  Bootstrap
//  Fonts
//  Global
//  Third party
//  Layout
//  Content
//---------------------------------------------------------------------------------*/
//
// =Bootstrap
// --------------------------------------------------
@import "custom-bootstrap";
//
// =Fonts
// --------------------------------------------------
@import "partials/fonts";
//
// =Global
// --------------------------------------------------
@import "general/mixin";
@import "general/setting";
@import "partials/constants";
@import "partials/colors";
@import "partials/typography";
@import "partials/forms";
@import "partials/buttons";
@import "partials/scrollbar-measure";
@import "partials/list-group";
@import "partials/modals";
//
// =Layout
// --------------------------------------------------
@import "layout/header";
@import "layout/footer";
@import "layout/content";
//
// =Third party
// --------------------------------------------------
@import "../third_party/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
@import "../third_party/Swiper/dist/css/swiper.css";
@import "../third_party/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";
//
// =Content
// --------------------------------------------------
@import "modules/video-slide";
@import "modules/featured";
@import "modules/schedule";
@import "modules/facebook-responsive";
@import "modules/article";

//
// =Page
// --------------------------------------------------
@import "pages/contact";
@import "pages/livestream";
@import "pages/about";
@import "pages/page";


