.article-content-imgabout {
  img {
    max-width: 800px;
    margin: 10px auto;
    display: block;
    height: auto;
  }
}

.about-tn {
  .box-content {
    -webkit-background-size: cover;
    background-size: cover;
    position: relative;
    padding: 50px;
    .about-tn-dv {
      float: right;
      max-width: 40%;
      .box-dv {
        background-color: rgba(#fff, 0.8);
      }
    }
  }
}

.partners-content {
  position: relative;
  .partners-slide {
    margin-left: auto;
    margin-right: auto;
    .swiper-slide {
      width: 25%;
      padding: 10px;
    }
    @media (max-width: $screen-sm-max) {
      .swiper-slide {
        width: 50%;
      }
    }

    @media (max-width: $screen-xs-max) {
      .swiper-slide {
        width: 100%;
      }
    }
  }
}

.about-tt {
  font-size: 24px;
  margin: 0 0 20px;
  text-transform: uppercase;
  font-weight: bold;
  @media (max-width: $screen-xs-max) {
    font-size: 18px;
  }
}

.about-info {
  background-color: #00355d;
  color: #ffffff;
  font-size: 16px;
  padding: 50px 0;
  .about-tt {
    font-size: 36px;
    margin: 0 0 30px;
    text-transform: uppercase;
    font-weight: bold;
  }
  @media (max-width: $screen-xs-max) {
    padding: 80px 15px;
    font-size: 14px;
    .about-tt {
      font-size: 24px;
      margin: 0 0 15px;
    }
  }
}

.about-history {
  background-image: url(../images/about-history.jpg);
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
  padding: 110px 0;
  min-height: 450px;
  article {
    float: left;
    width: 50%;
    @media (max-width: $screen-md-max) {
      width: 40%;
    }
    @media (max-width: $screen-md-max) {
      width: auto;
      float: none;
      padding: 20px;
      background-color: rgba(#FFF, 0.8);
    }
  }
  .about-tt {
    max-width: 345px;
    color: #1a7fc4;
  }
  @media (max-width: $screen-xs-max) {
    padding: 80px 15px;
    min-height: inherit;
    .about-tt {
      max-width: none;
    }
  }
}

.about-achievement {
  background-image: url(../images/about-achievement.jpg);
  background-repeat: no-repeat;
  background-position: left center;
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
  padding: 150px 0;
  min-height: 450px;
  article {
    float: right;
    width: 50%;
    @media (max-width: $screen-md-max) {
      width: auto;
      float: none;
      padding: 20px;
      background-color: rgba(#FFF, 0.8);
    }
  }
  .about-tt {
    color: #1a7fc4;
  }
  @media (max-width: $screen-xs-max) {
    padding: 80px 15px;
    min-height: inherit;
    .about-tt {
      max-width: none;
    }
  }
}

.about-mission {
  background-image: url(../images/about-mission.jpg);
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
  padding: 110px 0;
  min-height: 450px;
  article {
    float: left;
    width: 50%;
    p {
      span {
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 18px;
      }
    }
    @media (max-width: $screen-md-max) {
      width: auto;
      float: none;
      padding: 20px;
      background-color: rgba(#FFF, 0.8);
    }
  }
  .about-tt {
    color: #1a7fc4;
  }
  @media (max-width: $screen-xs-max) {
    padding: 80px 15px;
    min-height: inherit;
    .about-tt {
      max-width: none;
    }
  }
}
