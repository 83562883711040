.all-reporter-cover {
  margin-top: -20px;
}

.single-person {
  text-align: center;
  margin-bottom: 30px;
  h6 {
    font-size: 13px;
    color: #7f7f7f;
    font-weight: 400;
    font-style: italic;
  }
}

.page-nav-left {

}

.page-content-left, .page-content-right {
  width: 800px;
  @media (max-width: $screen-md-max) {
    width: 610px;
  }
  @media (max-width: $screen-sm-max) {
    width: 390px;
  }
  @media (max-width: $screen-xs-max) {
    width: auto;
    float: none;
  }
}

@media (min-width: $screen-sm) {
  .page-nav-left {
    float: left;
  }

  .page-nav-right {
    float: right;
  }


  .page-content-left {
    float: left;
  }

  .page-content-right {
    float: right;
  }

}

.page-nav-left, .page-nav-right {
  width: 300px;
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}
