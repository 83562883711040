$timeline-li: 120px;
$timeline-li-prev: 17px;

.timeline-hour {
  position: relative;
  z-index: 100;
  height: 40px;
  .timeline-hour-in {
    position: absolute;
    top: 0;
    height: 40px;
    width: 100%;
    border-top: 1px solid $color-style-dark;
    background-color: #fff;
    .sticky-active & {
      position: fixed;
      top: 84px;
      overflow: hidden;
      width: $container-lg - $grid-gutter-width;
      @media (max-width: $screen-md-max) {
        width: $container-md - $grid-gutter-width;
      }

      @media (max-width: $screen-sm-max) {
        width: $container-sm - $grid-gutter-width;
      }

      @media (max-width: $screen-xs-max) {
        width: auto;
        left: $grid-gutter-width-xs/2;
        right: $grid-gutter-width-xs/2;
      }
    }
  }
  .timeline-ul {
    @extend .clearfix;
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0 0 0 $timeline-li-prev;
    list-style-type: none;
    min-width: $timeline-li*48 + $timeline-li-prev;
    li {
      text-indent: -17px;
      padding-top: 15px;
      color: $color-style-dark;
      background-image: url(../images/schedule-time.png);
      background-repeat: no-repeat;
      float: left;
      width: $timeline-li;
    }
  }
}

.timeline-wrapper {
  background-color: #fff;
  padding-top: 40px;
  overflow: hidden;
  .timeline-scroll {
    background-color: #fff;
  }
  .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    top: -80px;
    bottom: auto;
    height: 40px;
    opacity: 1;
    .mCSB_dragger, .mCSB_dragger_bar {
      height: 40px !important;
      width: 40px !important;
    }
    .mCSB_dragger {
      margin-top: -13px;
    }
    .mCSB_dragger_bar {
      background-color: transparent !important;
      background-image: url(../images/schedule-time-button.png);
      -webkit-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      @include border-radius(0);
    }
    a, a + .mCSB_draggerContainer {
      margin-top: 12px;
      height: 16px;
    }
  }
  .sticky-active & {
    .mCSB_scrollTools.mCSB_scrollTools_horizontal {
      top: 44px;
      position: fixed;
      margin: auto;
      background-color: #fff;
      width: $container-lg - $grid-gutter-width;
      @media (max-width: $screen-md-max) {
        width: $container-md - $grid-gutter-width;
      }

      @media (max-width: $screen-sm-max) {
        width: $container-sm - $grid-gutter-width;
      }

      @media (max-width: $screen-xs-max) {
        width: auto;
        left: $grid-gutter-width-xs/2;
        right: $grid-gutter-width-xs/2;
      }
    }
  }
}

.timeline-content {
  margin: 0;
  padding: 0 0 0 $timeline-li-prev;
  list-style-type: none;
  background-image: url(../images/schedule-time-bg.png);
  background-position: -3px 0;
  li {
    float: left;
    clear: both;
    white-space: nowrap;
    span {
      float: left;
      display: inline-block;
      margin-right: 5px;
      line-height: 20px;
      font-size: 12px;
      &.timeline-range {
        clear: both;
        height: 7px;
        @include border-radius(3px);
        background-color: rgba(154, 202, 39, 1);
      }
      &.timeline-time {
        color: #000000;
      }
      &.timeline-title {

      }
    }
  }
}

.date-selector-wrapper {
  position: relative;
  height: 44px;
  z-index: 100;
  .date-selector-container {
    position: relative;
    background-color: $color-style-dark;
    padding: 0 30px;
    .sticky-active & {
      position: fixed;
      top: 0;
      overflow: hidden;
      width: $container-lg - $grid-gutter-width;
      @media (max-width: $screen-md-max) {
        width: $container-md - $grid-gutter-width;
      }

      @media (max-width: $screen-sm-max) {
        width: $container-sm - $grid-gutter-width;
      }

      @media (max-width: $screen-xs-max) {
        width: auto;
        left: $grid-gutter-width-xs/2;
        right: $grid-gutter-width-xs/2;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      /*-webkit-background-size: cover;
      background-size: cover;
      width: 15px;
      height: 25px;*/
    }
    .swiper-button-prev {
      left: 0px;
    }
    .swiper-button-next {
      right: 0px;
    }
    .date-selector {
      margin-left: auto;
      margin-right: auto;
      .swiper-slide {
        width: 5%;
        padding: 5px 1px;
        @media (max-width: $screen-md-max) {
          width: 10%;
        }
        @media (max-width: $screen-sm-max) {
          width: 20%;
        }
        @media (max-width: $screen-xs-max) {
          width: 25%;
        }
        a {
          display: block;
          font-size: 11px;
          background-color: #fff;
          text-align: center;
          line-height: 1.2;
          padding: 4px 0;
          &.current {
            background-color: $color-style-invert-dark;
            color: #ffffff;
          }
          &.active {
            background-color: $color-style-invert;
            color: #ffffff;
          }
        }
      }
      @media (max-width: $screen-md-max) {

      }

      @media (max-width: $screen-sm-max) {

      }

      @media (max-width: $screen-xs-max) {

      }
    }
  }
}
