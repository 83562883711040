.nav-footer {
  background-color: #7d7e7d;
  text-align: center;
  a {
    font-size: 12px;
    display: inline-block;
    margin: 0 5px;
    padding: 10px 10px;
  }
}

.footer {
  color: #ffffff;
  a {
    color: #ffffff;
  }
  .footer-text {
    background-color: $bg-color;
    padding-top: 10px;
    padding-bottom: 10px;
    .footer-left {
      margin-top: 10px;
      .footer-logo {
        img {
          height: 40px;
        }
      }
      p {
        margin-bottom: 0;
      }
    }

  }
  @media (min-width: $screen-md) {
    .footer-left {
      float: left;
      white-space: nowrap;
      .footer-logo {
        img {
          float: left;
          margin-right: 10px;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
    .footer-right {
      float: right;
      text-align: right;
    }
  }
  @media (max-width: $screen-sm-max) {
    font-size: 12px;
    text-align: center;
    .footer-left {
      img {
        margin-bottom: 10px;
      }
    }
  }
}
