//$color-style: #ed1c24;
$color-style: #F79323;
$color-style-light: #f8fa48;
//$color-style-dark: darken($color-style, 12);
$color-style-dark: darken($color-style, 12);
$color-style-invert: #EF5325;
$color-style-invert-dark: darken($color-style-invert, 12);

$line: #e1e1e1;

$black-text: #000;

$bg-color: #2B2B2B;
