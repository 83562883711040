.video-slide {
  &.swiper-container {
    width: 100%;
    height: 100%;
  }
  /*
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      @media (max-width: $screen-xs-max) {
        max-width: 100%;
      }
    }*/
  .bx-video {
    .cover {
      .title_news {
        padding-bottom: 30px;
        text-align: left;
        font-size: 24px;
        @media (max-width: $screen-xs-max) {
          font-size: 16px;
        }
      }
    }
  }
}

.container-video {
  padding: 30px 0;
  background-image: url(../images/bg-video-slide.jpg);
  -webkit-background-size: cover;
  background-size: cover;
}

.video-left {
  float: left;
  width: 800px;
  @media (max-width: $screen-md-max) {
    width: 620px;
  }
  @media (max-width: $screen-sm-max) {
    width: 400px;
  }
  @media (max-width: $screen-xs-max) {
    width: auto;
    float: none;
  }

  .video-player {
    .video-player-close {
      display: none;
    }
    @media (min-width: $screen-sm-min) {
      &.sticky-active:not(.none-fixed) {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 80;
        width: 400px;
        .video-player-close {
          display: block;
          position: absolute;
          top: -20px;
          left: -15px;
          font-size: 30px;
          z-index: 90;
          color: $color-style;
          cursor: pointer;
        }
      }
    }
  }
}

.video-action-info {
  margin-top: 15px;
  .btn.pull-left {
    margin-right: 10px;
  }
  .btn-default {
    font-size: 13px;
    height: 28px;
    padding: 0 4px 0 6px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #4267b2;
    border: 1px solid #4267b2;
    color: #ffffff;
  }
}

.video-right {
  float: right;
  width: 300px;
  @media (max-width: $screen-xs-max) {
    width: auto;
    float: none;
  }
  .videobox-highlight {
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    .highlight-title {
      text-transform: uppercase;
      font-size: 18px;
      margin: 0;
      padding: 10px 15px;
      border-bottom: 1px solid #FFFFFF;
      background-color: rgba(0, 0, 0, 0.3);
    }
    .highlight-content {
      height: 405px;
      overflow: hidden;
      &.highlight-videodetail {
        height: 395px;
      }
      @media (max-width: $screen-md-max) {
        height: 303px;
        &.highlight-videodetail {
          height: 293px;
        }
      }
      @media (max-width: $screen-sm-max) {
        height: 179px;
        &.highlight-videodetail {
          height: 169px;
        }
      }
      @media (max-width: $screen-xs-max) {
        height: auto;
        &.highlight-videodetail {
          height: auto;
        }
        .list-video-thumbs li {
          &:last-child, &:nth-last-child(2) {
            display: none;
          }
        }
      }
      /*ul {
        li {
          border-bottom-width: 0;
          &:nth-child(2n) {
            background-color: rgba(0, 0, 0, 0.3);
          }
        }
        &.list-video .list-video-box img {
          height: 40px;
          width: auto;
        }
      }*/
    }
  }
}

.list-video-thumbs {
  margin: 10px 0 0px;
  padding: 0 5px;
  overflow: hidden;
  list-style-type: none;
  li {
    float: left;
    width: 50%;
    padding: 0 5px;

    .bx-news {
      margin-bottom: 10px;
      font-size: 12px;
      .title_news {
        background-color: transparent;
        padding: 5px 0;
        .article-title {
          height: 36px;
        }
      }
      .article-meta {
        display: none;
      }
      span.view-count,
      span.like-count {
        display: none;
      }
    }
  }
}
