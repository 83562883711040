a {
  color: inherit;
}

a, .btn {
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: 0 none;
      text-decoration: none;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    outline: 0 none;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0 none;
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: bold;
  line-height: 1.4;
}

body {
  font-family: 'Roboto', sans-serif;
}

.img-full {
  width: 100%;
  height: auto;
}

.header-t1 {
  margin-top: 0;
  h1 {
    margin-top: 0;
  }
}

.zmdi-caret-right {
  &.red {
    color: $color-style-dark;
  }
}

.swiper-button-prev, .swiper-button-next {
  @extend .zmdi;
  background-image: none;
  font-size: 40px;
  color: $color-style;
  &:hover {
    color: $color-style-dark;
  }
  &:before {
    height: 44px;
    margin-top: -28px;
    font-size: 100px;
    display: inline-block;
    float: left;
  }
}

.swiper-button-prev {
  @extend .zmdi-chevron-left;
}

.swiper-button-next {
  @extend .zmdi-chevron-right;
}
