.img-bg {
  max-width: 100%;
  height: auto;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}

.wrapper {
  overflow: hidden;
}

/* ==========================================================================
Home page
========================================================================== */
.main {

}

.container-video {
  margin-bottom: 30px;
}

.container-content {
  margin-top: 20px;
}

.content-full {
  padding: 30px 0;
}

.content-left {
  float: left;
  width: 820px;
  position: relative;

  .content-left-ads {
    float: right;
    display: none;
    width: 160px;
    height: 600px;
    a {
      display: block;
    }
  }

  @media (min-width: $screen-md-min) {
    
    &.sticky-active:not(.sticky-bottom-active) {
      .content-left-ads-fixed {
        position: fixed;
        top: 0;
      }
    }
    &.sticky-bottom-active {
      .content-left-ads {
        position: absolute;
        bottom: 0;
        right: 0;
        top: auto;
      }
    }
  }
  @media (min-width: $screen-md-min) {

    .content-left-page {
      float: left;
      width: 610px - 180px;
    }
    .content-left-ads {
      display: block;
    }
  }
  @media (min-width: $screen-lg-min) {
    .content-left-page {
      width: 820px - 180px;
    }
  }

  @media (max-width: $screen-md-max) {
    width: 610px;
  }
  @media (max-width: $screen-sm-max) {
    width: 390px;
  }
  @media (max-width: $screen-xs-max) {
    width: auto;
    float: none;
  }
}

.content-right {
  float: right;
  width: 300px;
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

.box-group {
  margin-bottom: 30px;
  .box-title {
    border-bottom: 1px solid $color-style-dark;
    margin-bottom: 20px;
    @extend .clearfix;
    .title {
      float: left;
      font-size: 20px;
      color: $color-style;
      margin: 0;
      text-transform: uppercase;
      @media (max-width: $screen-xs-max) {
        font-size: 14px;
      }
      span {
        display: inline-block;
        position: relative;
        padding: 5px 0;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1px;
          height: 1px;
          background-color: $color-style-invert;
        }
      }
    }
    .box-title-action {
      float: right;
      padding: 5px 10px;
      background-color: $color-style-dark;
      color: #ffffff;
      font-size: 12px;
      position: relative;
      bottom: -11px;
      @media (max-width: $screen-xs-max) {
        bottom: -3px;
      }
      &:before {
        content: "";
        position: absolute;
        left: -15px;
        bottom: 0;
        width: 0;
        height: 0;
        border-bottom: 27px solid $color-style-dark;
        border-left: 15px solid transparent;
      }
      a {
        color: #ffffff;
      }
      .dropdown {
        padding-right: 5px;
      }
      .dropdown-menu {
        @include border-radius(0);
        background-color: $color-style-dark;
        right: -10px;
        margin-top: 5px;
        border-width: 0;
        @include box-shadow(0 0 0 transparent);
        font-size: 12px;
        a {
          &:hover {
            background-color: transparent;
            color: #FFFF99;
          }
        }
      }
    }
  }
}

.video-show-gr {
  margin-left: -(floor(($grid-gutter-width / 2)));
  margin-right: -(floor(($grid-gutter-width / 2)));
  padding: 0;
  list-style-type: none;
  @media (max-width: $screen-xs-max) {
    margin-left: -(floor(($grid-gutter-width-xs / 2)));
    margin-right: -(floor(($grid-gutter-width-xs / 2)));
  }
  > li {
    float: left;
    width: 228px;
    padding-left: floor(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));
    margin-bottom: 23px;
    &:first-child {
      width: 476px;
      .title_news {
        font-size: 20px;
        padding-bottom: 10px;
      }
    }
    @media (max-width: $screen-md-max) {
      width: 190px;
      margin-bottom: 18px;
      &:first-child {
        width: 390px;
      }
    }

    @media (max-width: $screen-sm-max) {
      width: 185px;
      margin-bottom: 11px;
      &:first-child {
        width: 370px;
      }
    }

    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      &:nth-child(6), &:nth-child(7) {
        display: none;
      }
    }

    @media (max-width: $screen-xs-max) {
      width: 50%;
      padding-left: floor(($grid-gutter-width-xs / 2));
      padding-right: floor(($grid-gutter-width-xs / 2));
      margin-bottom: 15px;
      &:first-child {
        width: auto;
      }
    }
  }
}

.bx-video {
  .cover {
    position: relative;
    display: block;
    overflow: hidden;
    &:hover {
      .title_news {
        color: $color-style-dark;
      }
    }
    .title_news {
      color: #ffffff;
      margin: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparent linear-gradient(to top, $black-text, transparent);
      padding: 25px 15px 5px;
      @include transition(500ms);
    }
  }
}

.bx-news {
  margin-bottom: 20px;
  .cover {
    display: block;
    position: relative;
  }
  a {
    @include transition(500ms);
    &:hover {
      color: $color-style-dark;
    }
    &:after {

    }
  }
  .title_news {
    background-color: #ffffff;
    padding: 10px 0;
    p {
      margin-bottom: 0;
    }
    .article-meta {
      margin-bottom: 5px;
      font-size: 12px;
      span {
        display: inline-block;
        margin-right: 10px;
        @media (max-width: $screen-sm-max) {
          &.view-count, &.like-count {
            display: none;
          }
        }
      }
    }
    .article-title {
      font-weight: bold;
      height: 40px;
      overflow: hidden;
      a {
        display: block;
      }
      &.no-fixed {
        display: table-cell;
        width: 10000px;
        vertical-align: middle;
      }
    }
  }

  .watch-detail & .title_news .article-title {
    height: auto;
  }

  &.article-detail {
    margin-top: -20px;
    .article-title {
      margin: 0;
      padding: 0;
      height: auto;
      font-size: 24px;
    }
    .summary {
      margin: 10px 0;
      font-weight: bold;
    }
    .article-relative {
      margin: 15px 0;
      a {
        font-size: 12px;
        color: #666666;
        font-weight: bold;
        font-style: italic;
        &:hover {
          color: $color-style-dark;
        }
      }
    }
  }
}

.cover {
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 0px;
    margin-left: -15px;
    display: inline-block;
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    height: 30px;
    width: 30px;
    opacity: 0;
    color: $color-style-dark;
    @include transition(All 0.3s ease);
  }
  &:hover:after {
    margin-top: -25px;
    opacity: 1;
  }
  .bx-video &:after {
    content: "\f3a8";
  }
  .bx-news &:after {
    content: "\f3a8";
    margin: 0;
    bottom: 10px;
    left: 10px;
    top: auto;
    opacity: 1;
    color: #ffffff;
  }
  .bx-news.news &:after {
    content: "\f18e";
    bottom: 5px;
  }
  .bx-news &:hover:after {
    color: $color-style-dark;
  }

  @media (min-width: $screen-sm-min) {
    .video-slide &:after,
    .video-show-gr li:first-child &:after {
      font-size: 80px;
      height: 80px;
      width: 80px;
      margin-left: -40px;
    }
    .video-slide &:after,
    .video-show-gr li:first-child &:hover:after {
      margin-top: -40px;
    }
  }
}

.list-news {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-bottom: 1px solid #cdcdcd;
    &:last-child {
      border-bottom-width: 0;
    }
    a {
      display: block;
      padding: 10px;
      font-size: 12px;
    }
  }
}

.list-video {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-bottom: 1px solid #cdcdcd;
    &:last-child {
      border-bottom-width: 0;
    }
  }
  .list-video-box {
    display: block;
    padding: 12px 10px 7px;
    position: relative;
    overflow: hidden;
    img {
      float: left;
      width: 80px;
      margin-right: 10px;
    }
    span {
      overflow: hidden;
      font-size: 12px;
      line-height: 1.2;
      max-height: 54px;
      overflow: hidden;
    }
    &:hover {
      color: $color-style;
    }
  }
  &.rank {
    counter-reset: section;
    li:first-child .list-video-box:after {
      color: #76c917;
    }
    li:nth-child(2) .list-video-box:after {
      color: #ed1c24;
    }
    li:nth-child(3) .list-video-box:after {
      color: #0089d0;
    }
    .list-video-box {
      &:before {
        counter-increment: section;
        content: counter(section);
        position: absolute;
        top: 10px;
        left: 5px;
        z-index: 2;
        width: 16px;
        text-align: center;
        font-size: 12px;
        color: #ffffff;
      }

      &:after {
        display: inline-block;
        font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\f11e';
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 1;
        font-size: 30px;
        color: #525b6e;
      }
    }
  }
}

.nav-tabs-wrap {
  .nav-tabs-control {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: table;
    width: 100%;
    > li {
      display: table-cell;
      a {
        display: block;
        padding: 5px;
        text-align: center;
        color: $color-style;
      }
      &.active {
        a {
          background-color: $color-style;
          color: #ffffff;
        }
      }
    }
  }
  .tab-content {
    border: 10px solid $color-style;
    background-color: #ffffff;
  }
  .nav-tabs-container {
    .nav-tabs-content {
      margin: 0;
      padding: 3px 0 0;
      list-style: none;
      li {
        padding: 2px 10px 3px;
        margin-bottom: 3px;
        border-bottom: 1px solid $line;
        &:last-child {
          margin-bottom: 0;
          border-bottom-width: 0;
        }
      }
    }
  }
  $schedule-list-wrap: 354px;
  $schedule-list-wrap-md: 245px;
  $schedule-list-wrap-sm: 217px;
  $schedule-list-h: 44px;
  .schedule-list-wrap {
    height: $schedule-list-wrap;
    @media (max-width: $screen-md-max) {
      height: $schedule-list-wrap-md;
    }
    @media (max-width: $screen-sm-max) {
      height: $schedule-list-wrap-sm;
    }
  }
  .schedule-broadcast-wrap {
    height: $schedule-list-wrap + $schedule-list-h;
    @media (max-width: $screen-md-max) {
      height: $schedule-list-wrap-md + $schedule-list-h;
    }
    @media (max-width: $screen-sm-max) {
      height: $schedule-list-wrap-sm + $schedule-list-h;
    }
  }
}

.schedule-horizontal {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: justify;
  background-color: $color-style;
  height: 44px;
  line-height: 1.1;
  &:after {
    content: "";
    width: 100%;
    display: inline-block;
  }
  > li {
    display: inline-block;
    a {
      display: block;
      font-size: 10px;
      color: $color-style-invert;
      padding: 5px;
      text-align: center;
      background-color: #ffffff;
      &.active {
        color: #ffffff;
        background-color: $color-style-invert;
      }
    }
  }
  .videobox-highlight & {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.schedule-list-tt {
  padding: 2px 10px 3px;
  margin: 10px 0;
}

.schedule-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li:first-child {
    h3 {
      margin-top: 0;
    }
  }
  h3 {
    font-size: 16px;
    text-transform: uppercase;
  }
  .highlight-content & {
    li {
      padding: 7px 10px;
      &.has-link {
        position: relative;
        &:hover {
          background-color: rgba(0, 0, 0, 0.3);
          a {
            color: #ffffff;
          }
        }
      }
    }
  }
  .schedule-time {
    float: left;
    width: 60px;
  }
  .schedule-programs {
    margin-left: 60px;
    .schedule-name {
      margin: 0;
      font-weight: bold;
      a {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 2;
        }
        &:before {
          display: inline-block;
          font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: '\f2d9';
          margin-right: 2px;
          color: $color-style-dark;
        }
      }
    }
    .schedule-desc {
      font-size: 12px;
    }
  }
}

/* ==========================================================================
Aside
========================================================================== */
.bx-aside {
  margin-bottom: 30px;
}

.aside-title {
  text-transform: uppercase;
  font-size: 16px;
  color: $color-style;
  margin: 0 0 15px;
  font-weight: normal;
}

.aside-radio-content {
  margin: 0;
  background-color: #ffffff;
  dt {
    background-color: #e1e1e1;
    padding: 5px 10px;
    text-transform: uppercase;
  }
  dd {
    white-space: nowrap;
    font-style: italic;
    &.current {
      padding: 5px 10px;
      font-weight: bold;
    }
    .aside-radio-other {
      max-height: 310px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        border-bottom: 1px solid $line;
        padding: 5px 10px;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }
    }
  }
}

.container-program {
  //background-image: url(../images/bg-video-program.jpg);
  //background-image: url(../images/bg-gallery.jpg);

  -webkit-background-size: cover;
  background-size: cover;
  padding: 20px 0 40px 0;
  .program-title {
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    margin: 0 0 20px;
    color: $color-style;
  }
}

.program-content {
  position: relative;
  .program-slide {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
    .swiper-slide {
      width: 320px;
      padding: 15px;
      .cover {
        border: 3px solid $color-style;
        display: block;
      }
    }

    @media (max-width: $screen-md-max) {
      width: 900px;
      .swiper-slide {
        width: 300px;
      }
    }

    @media (max-width: $screen-sm-max) {
      width: auto;
      .swiper-slide {
        width: 50%;
      }
    }

    @media (max-width: $screen-xs-max) {
      .swiper-slide {
        width: 100%;
      }
    }
  }
}

.box-content {
  .row {
    > .col-md-4 {
      @media (min-width: $screen-md-min) {
        &:nth-child(3n+1) {
          clear: both;
        }
      }
    }
    > .col-xs-6 {
      @media (max-width: $screen-sm-max) {
        &:nth-child(2n+1) {
          clear: both;
        }
      }
      @media (max-width: $screen-xs-max) {
        &:nth-child(2n+1) {
          clear: both;
        }
      }
    }
  }
}

.skin1 {
  .box-content {
    @extend .clearfix;
    & + .box-content {
      margin-top: 20px;
    }
  }
  .list-unstyled {
    margin-bottom: 0;
    > li {
      .bx-news {
        margin-bottom: 0;
      }
      &:nth-child(1) {
        .bx-news {
          margin-bottom: 10px;
        }
      }
      &:nth-child(1), &:nth-child(2) {
        float: left;
        width: 460px;
        border-right: 1px solid $line;
        @media (max-width: $screen-md-max) {
          width: 400px;
        }
        @media (max-width: $screen-sm-max) {
          width: auto;
          float: none;
          border-right-width: 0;
        }
        .bx-news {
          display: table;
          width: 100%;
          .title_news {
            padding: 0 15px;
          }
          .cover, .title_news {
            display: table-cell;
            vertical-align: top;
            padding-top: 0;
            padding-bottom: 0;
          }
          figure {
            width: 150px;
            @media (max-width: $screen-sm-max) {
              width: 90px;
            }
          }
          .article-title {
            margin-bottom: 5px;
            max-height: 40px;
            overflow: hidden;
            @media (max-width: $screen-xs-max) {
              height: auto;
            }
          }
          .summary {
            max-height: 60px;
            overflow: hidden;
          }
        }
      }

      &:nth-child(3) {
        @media (max-width: $screen-sm-max) {
          border-top: 1px solid $line;
          padding-top: 10px;
          margin-top: 10px;
        }
      }
      &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        margin-left: 480px;
        margin-bottom: 10px;
        padding-bottom: 9px;
        border-bottom: 1px solid $line;
        @media (max-width: $screen-md-max) {
          margin-left: 410px;
        }
        @media (max-width: $screen-sm-max) {
          margin-left: 0;
        }
        .cover, .summary {
          display: none;
        }
        .title_news {
          margin: 0;
          padding: 0;
        }
        .article-title {
          max-height: 40px;
          overflow: hidden;
          @media (max-width: $screen-xs-max) {
            height: auto;
            max-height: inherit;
          }
          a {
            &:before {
              display: inline-block;
              font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: '\f2f6';
              margin-right: 5px;
              color: $color-style;
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom-width: 0;
      }
    }
  }
}

.skin2 {
  .box-content {
    @extend .clearfix;
  }
  .list-unstyled {
    margin-bottom: 0;
    > li {
      .bx-news {
        margin-bottom: 0;
      }
      .title_news {
        padding: 0;
      }
      .summary {
        display: none;
      }
      @media (min-width: $screen-lg-min) {
        &:first-child {
          float: left;
          width: 330px;
          margin-right: 20px;
          .article-title {
            margin-top: 10px;
            font-size: 18px;
            height: 50px;
          }
        }
        &:nth-child(2) {
          margin-right: 20px;
        }
        &:nth-child(2), &:nth-child(3) {
          float: left;
          width: 215px;
          border-top: 1px solid $line;
          .article-title {
            margin-top: 5px;
          }
        }
      }
      @media (max-width: $screen-md-max) {
        &:first-child {
          img {
            width: 100%;
            height: auto;
          }
          .article-title {
            margin-top: 5px;
            font-size: 16px;
          }
        }
        &:nth-child(2), &:nth-child(3) {
          float: left;
          width: 50%;
          margin-bottom: 10px;
          .article-title {
            margin-top: 5px;
          }
        }
        &:nth-child(2) {
          padding-right: 10px;
        }
        &:nth-child(3) {
          padding-left: 10px;
        }
      }
      &:nth-child(4) {
        border-top: 1px solid $line;
        margin-top: 15px;
        padding-top: 10px;
      }
      &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        float: left;
        width: 400px;
        @media (max-width: $screen-md-max) {
          float: none;
          width: auto;
          clear: both;
        }
        .article-title {
          height: auto;
          margin-top: 5px;
          a {
            &:before {
              display: inline-block;
              font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: '\f2f6';
              margin-right: 5px;
              color: $color-style;
            }
          }
        }
      }
    }
  }
}

.skin-cate {
  ul {
    li {
      clear: both;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $line;
      .cover {
        width: 210px;
        float: left;
        margin-right: 15px;
      }
      .title_news {
        overflow: hidden;
      }
    }
  }
  .bx-news {
    overflow: hidden;
    margin-bottom: 0;
    .title_news {
      padding: 0;
      @media (max-width: $screen-sm-max) {
        .article-title {
          height: auto;
        }
      }
    }
  }
}

.grid-news {
  @include clearfix;
  .cover {
    float: left;
    margin-right: 10px;
    img {
      width: 110px;
    }
  }
  .title_news {
    overflow: hidden;
    .article-meta {
      display: none;
    }
    .article-title {
      max-height: 60px;
      margin-bottom: 0;
      overflow: hidden;
      line-height: 20px;
      a {
        display: block;
        &:hover {
          color: $color-style;
        }
      }
    }
  }
}

.pagination-wrap {
  clear: both;
  text-align: center;
  .pagination {
    margin-top: 0;
  }
}

/*
  video detail
*/

.watch-detail {
  margin-bottom: 30px;
  .article-title {
    height: auto;
    font-size: 24px;
    margin: 0;
  }
}

.box-likeshare {
  clear: both;
  margin: 10px 0;
}

.box-title-search {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
  @media (max-width: $screen-sm-max) {
    .row > [class^="col-"] {
      margin-bottom: 10px;
    }
  }
  .filter-video {
    padding: 0 20px;
    background-color: #fff;
    z-index: 10;
    a {
      display: block;
      margin: 10px 0;
      padding-bottom: 10px;
      border-bottom: 1px solid $line;
      &:last-child {
        margin-bottom: 0;
      }
    }
    br {
      display: none;
    }
  }
  @media (min-width: $screen-sm-min) {
    .filter-video {
      position: absolute;
      left: 0;
      right: 0;
    }
  }
}

/*
  News detail
*/

.breadcrumb-wrap {
  .breadcrumb {
    @include border-radius(0);
    background-color: #fff;
    padding-left: 0;
    padding-top: 0;
    margin-bottom: 20px;
    border-bottom: 1px solid $line;
  }
}

.article-content {
  img {
    display: block;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
  }
  table {
    max-width: 100% !important;
    @media (max-width: $screen-xs-max) {
      width: 100% !important;
    }
  }
}

.social-bxcount {
  padding-top: 60px;
  background-image: url(../images/like-fanpage-ktv.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  @media (max-width: $screen-xs-max) {
    -webkit-background-size: contain;
    background-size: contain;
    padding-top: 55px;
  }
  ul {
    li {
      float: left;
      margin-right: 10px;
      &:last-child {
        padding-left: 150px;
        margin-right: 0;
        @media (max-width: $screen-xs-max) {
          float: right;
          padding-left: 0;
        }
      }
    }
  }
}

.tags-wrap {
  margin: 20px 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid $line;
  border-bottom: 1px solid $line;
  padding-left: 40px;
  position: relative;
  span {
    position: absolute;
    left: 0;
    top: 10px;
    font-weight: bold;
  }
  a {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 0px 10px;
    @include border-radius(10px);
    background-color: $line;
    font-size: 12px;
  }
}

.article-comment, .box-likeshare {
  .fb_iframe_widget,
  .fb_iframe_widget span,
  .fb_iframe_widget span iframe[style] {
    min-width: 100% !important;
    width: 100% !important;
  }
}

.facebook-comment {
  #form-comment {
    margin-bottom: 20px;
  }
  .item {
    margin-top: 20px;
  }
  .avatar {
    display: none;
    float: left;
    width: 70px;
    .zmdi {
      font-size: 70px;
      width: 70px;
      text-align: center;
      background-color: #dbdbdb;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
  }
  .message {
    /*margin-left: 80px;*/
    margin-left: 0;
    .content {
      margin-bottom: 10px;
      .name {
        display: block;
        margin-bottom: 5px;
      }
    }
    .dated {
      font-size: 12px;
      span {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}

.article-relative-list {
  @include box-shadow(0 0 7px 1px rgba(218, 218, 218, 0.8));
  padding: 15px;
  margin: 15px 0;
  h4 {
    color: $color-style-dark;
  }
  ul {
    li {
      margin-bottom: 10px;
    }
  }
}

.box-dv {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
}

.search-result-key {
  h2 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 24px;
  }
}

.view-more {
  text-align: center;
  position: relative;
  &:before {
    content: "";
    height: 1px;
    position: absolute;
    background-color: $color-style;
    top: 50%;
    left: 0;
    right: 0;
  }
  a {
    position: relative;
    z-index: 2;
    display: inline-block;
    color: $color-style;
    border: 1px solid $color-style;
    padding: 6px 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #fff;
  }
}

.list-group-video {

}

.social2 a {
  display: inline-block;
  text-indent: -99999px;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-image: url(../images/icon.png);
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.social2 a.ico-fb {
  background-position: 0px 0;
}

.social2 a.ico-tw {
  background-position: -30px 0;
}

.social2 a.ico-gg {
  background-position: -60px 0;
}

@media (min-width: 768px) {
  .social2 a {
    margin-right: 5px;
  }
}
