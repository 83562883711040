/* *****************************************************
   * TOGGLE SWITCH STYLES
   ***************************************************** */
// try different settings like
// 18px / 55px
// 25px / 65px
// 16px / 60px
$borderRadius: 16px;
$toggleWidth: 82px;

$standardColor: #38a838;
$altColor: #307fc5;

$standardOnText: 'TV&Video';
$standardOffText: 'Tin Tức';
$altOnText: 'Yes';
$altOffText: 'No';
.navbar-nav {

  .toggle {
    margin-top: 8px;
    margin-bottom: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: $toggleWidth;
    height: $borderRadius +2 +3*2;
    padding: 3px;
    background-color: white;
    border-radius: $borderRadius;
    box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
    background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
    background-image: -o-linear-gradient(top, #eeeeee, white 25px);
    background-image: linear-gradient(to bottom, #eeeeee, white 25px);
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .toggle-text {
    position: relative;
    display: block;
    height: $borderRadius +2;
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
    -webkit-transition: 0.15s ease-out;
    -moz-transition: 0.15s ease-out;
    -o-transition: 0.15s ease-out;
    transition: 0.15s ease-out;
    -webkit-transition-property: opacity background;
    -moz-transition-property: opacity background;
    -o-transition-property: opacity background;
    transition-property: opacity background;
  }

  .toggle-text:before,
  .toggle-text:after {
    position: absolute;
    top: 50%;
    margin-top: -.4rem;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
  }

  .toggle-text:before {
    content: $standardOffText;
    right: 14px;
    color: #999;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
  }

  .toggle-text:after {
    content: $standardOnText;
    left: 9px;
    color: white;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
  }

  .checkbox:checked ~ .toggle-text {
    background: $standardColor;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
  }

  .checkbox:checked ~ .toggle-text:before {
    opacity: 0;
  }

  .checkbox:checked ~ .toggle-text:after {
    opacity: 1;
  }

  .toggle-handle {
    position: absolute;
    top: 4px;
    left: 4px;
    width: $borderRadius;
    height: $borderRadius;
    background: white;
    border-radius: $borderRadius / 2;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
    background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
    background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
    background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: left 0.15s ease-out;
    -o-transition: left 0.15s ease-out;
    transition: left 0.15s ease-out;
  }

  .toggle-handle:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: #f9f9f9;
    border-radius: 6px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
    background-image: -webkit-linear-gradient(top, #ccc, #eee);
    background-image: -moz-linear-gradient(top, #ccc, #eee);
    background-image: -o-linear-gradient(top, #ccc, #eee);
    background-image: linear-gradient(to bottom, #ccc, #eee);
  }

  .checkbox:checked ~ .toggle-handle {
    left: $toggleWidth - $borderRadius -4px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
  }

  .checkbox:checked ~ .toggle-handle:before {
    background-image: -webkit-linear-gradient(top, $standardColor, lighten($standardColor, 15%));
    background-image: -moz-linear-gradient(top, $standardColor, lighten($standardColor, 15%));
    background-image: -o-linear-gradient(top, $standardColor, lighten($standardColor, 15%));
    background-image: linear-gradient(to bottom, $standardColor, lighten($standardColor, 15%));
  }

  .toggle-alternative > .checkbox:checked ~ .toggle-text {
    background: $altColor;
  }

  .toggle-alternative > .checkbox:checked ~ .toggle-handle:before {
    background-image: -webkit-linear-gradient(top, $altColor, lighten($altColor, 15%));
    background-image: -moz-linear-gradient(top, $altColor, lighten($altColor, 15%));
    background-image: -o-linear-gradient(top, $altColor, lighten($altColor, 15%));
    background-image: linear-gradient(to bottom, $altColor, lighten($altColor, 15%));
  }

  .toggle-alternative .toggle-text:before {
    content: $altOffText;
    right: 11px;
    color: #999;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
  }

  .toggle-alternative .toggle-text:after {
    content: $altOnText;
    left: 11px;
    color: white;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
  }
}

.btn {
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: 0 none;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    outline: 0 none;
  }
}

@mixin button-variant-inline($color, $background, $border) {

  @if $background == transparent {
    $background: #FFF;
  }

  color: $color;
  background-color: $background;
  border-color: $border;
  @include transition($transition-base);

  &:focus,
  &.focus {
    color: $background;
    background-color: darken($color, 10%);
    border-color: darken($color, 25%);
  }
  &:hover {
    color: $background;
    background-color: darken($color, 10%);
    border-color: darken($color, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $background;
    background-color: darken($color, 10%);
    border-color: darken($color, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $background;
      background-color: darken($color, 17%);
      border-color: darken($color, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $color;
      border-color: $color;
    }
  }

  .badge {
    color: $color;
    background-color: $background;
  }
}

.btn-min {
  min-width: 150px;
}

.btn-primary-inline {
  @include button-variant-inline($color-style, transparent, transparent);
}

.btn-primary-outline {
  @include button-variant-inline($color-style, transparent, $color-style);
}

.btn-facebook {
  @include button-variant-inline($bg-facebook, transparent, $color-style);
}

.btn-no-shadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}


